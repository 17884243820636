import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'

const _20be153a = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _25f9524c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

const scrollBehavior = function(to, from, savedPosition) {
      return { x: 0, y: 0 }
    }

export function createRouter() {
  return new Router({
    mode: 'history',
    base: decodeURI('/'),
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'is-active',
    scrollBehavior,

    routes: [{
      path: "/login",
      component: _20be153a,
      name: "login"
    }, {
      path: "/",
      component: _25f9524c,
      name: "index"
    }],

    fallback: false
  })
}

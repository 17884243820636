<template>
  <div>
    <navbar />
    <nuxt />
    <footer-c />
  </div>
</template>

<script>
import navbar from '~/components/theme/navbar'
import footerC from '~/components/theme/footer'

export default {
  components: {
    navbar,
    footerC
  }
}
</script>

import {
  Validator,
  install as VeeValidate
} from 'vee-validate/dist/vee-validate.minimal.esm.js'
import {
  // eslint-disable-next-line camelcase
  alpha_spaces,
  email,
  max,
  required
} from 'vee-validate/dist/rules.esm.js'
import Vue from 'vue'

/**
 * Add Finnish localization
 */
Validator.localize('fi', {
  name: 'fi',
  messages: {
    _default: field => `${field} ei ole validi.`,
    confirmed: field => `${field} ei täsmää`,
    max: (field, [length]) =>
      `${field} ei saa olla pidempi kuin ${length} merkkiä.`,
    required: field => `${field} on pakollinen kenttä.`
  }
})

/**
 * Add default rules
 */
Validator.extend('confirmed', alpha_spaces)
Validator.extend('email', email)
Validator.extend('max', max)
Validator.extend('required', required)

/**
 * Insert validator into Vue
 */
Vue.use(VeeValidate, {
  inject: false,
  locale: 'fi',
  events: 'change'
})

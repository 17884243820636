import axios from 'axios'

export const state = () => ({
  authUser: null
})

export const mutations = {
  SET_USER: function(state, user) {
    state.authUser = user
  }
}

export const actions = {
  // nuxtServerInit is called by Nuxt.js before server-rendering every page
  nuxtServerInit({ commit }, { req }) {
    if (req.user) {
      commit('SET_USER', req.user)
    }
  },
  async login({ commit }, { email, password }) {
    if (!email || !password) throw new Error('Email and password are required')
    try {
      const { data } = await axios.post('/api/auth/login', { email, password })
      commit('SET_USER', data)
    } catch (error) {
      if (error.response.status === 422) {
        return error.response.data
      } else {
        throw new Error('Tarkista tunnukset')
      }
    }
  },
  // eslint-disable-next-line camelcase
  async register({ commit }, { email, password, password_confirmation }) {
    if (!email || !password) throw new Error('Email and password are required')
    try {
      await axios.post('/api/auth/register', {
        email,
        password,
        password_confirmation
      })
    } catch (error) {
      switch (error.response.status || 500) {
        case 409:
          throw new Error('Such email is already registered')
        case 422:
          return error.response.data
        case 500:
          throw new Error('Internal server error')
      }
    }
  },
  async logout({ commit }) {
    const { data } = await axios.post('/api/auth/logout')
    if (data.success) commit('SET_USER', null)
  },
  async changePassword({ commit }, { currentPassword, newPassword }) {
    if (!currentPassword || !newPassword)
      throw new Error('All fields are required')
    try {
      await axios.patch('/api/auth', { currentPassword, newPassword })
      commit('SET_USER', null)
    } catch (error) {
      throw new Error('Wrong password')
    }
  }
}
